<template>
  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Aprovações"
    />

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="hit === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="hit">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <aprovacao-editar-informacoes-card :hit="hit" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >

          <aprovacao-editar-botoes-card :hit="hit" />
          
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <aprovacao-editar-letra-card :hit="hit" />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BFormRating, BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'
import AprovacaoEditarInformacoesCard from './AprovacaoEditarInformacoesCard.vue'
import AprovacaoEditarBotoesCard from './AprovacaoEditarBotoesCard.vue'
import AprovacaoEditarLetraCard from './AprovacaoEditarLetraCard.vue'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    AprovacaoEditarInformacoesCard,
    AprovacaoEditarBotoesCard,
    AprovacaoEditarLetraCard,

    InvoiceList,
    VueElementLoading,
    BFormRating,
  },
  data() {
    return {  

      hit: {},
      carregamentoApi: false,

    }
  },

  created() {
    this.hitId()
  },
  methods: {

    // CARREGAR APROVAÇÕES

    hitId() {
      this.carregamentoApi = true

      useJwt.hit({
        id: router.currentRoute.params.id,
      })
        .then(response => {
          this.hit = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

  },

}
</script>

<style>

</style>
