<template>
  <b-card title="Letra">

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      @click="editarLetra"
      class="mb-2"
      v-if="!textAreaAtivo"
    >
      <span>
        Editar
      </span>
      
    </b-button>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      @click="fecharEditor"
      class="mb-2"
      v-else
    >
      <span>
        Fechar Editor
      </span>
      
    </b-button>

    <div 
      class="text-with-line-breaks textoLetra"
      v-show="!textAreaAtivo"
    >
      {{ hit.letra }}
    </div>

    <div class="campoLetra">
      <b-form-textarea
        id="hitLetra"
        v-model="hitLetraEditada"
        placeholder="Letra da música"
        rows="15"
        v-show="textAreaAtivo"
      />
    </div>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="salvarLetra"
      class="mt-2"
      v-show="textAreaAtivo"
    >
      <span>
        Salvar
      </span>
      
    </b-button>

  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BButton, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BFormTextarea,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      textAreaAtivo: false,
      hitLetraEditada: null,
    }
  },
  methods: {

    editarLetra() {
      
      if (this.textAreaAtivo) {
        this.textAreaAtivo = false
      } else {
        this.textAreaAtivo = true
        this.hitLetraEditada = this.hit.letra
      }

    },

    fecharEditor() {

      this.hitLetraEditada = this.hit.letra
      this.textAreaAtivo = false

    },

    // EDITAR LETRA
    salvarLetra() {
      this.$swal({
        title: 'Deseja editar essa letra?',
        text: 'Essa ação não poderá ser revertida.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, editar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API
          useJwt.hitLetraEditar({
            hitId: this.hit.id,
            letra: this.hitLetraEditada
          })
            .then(response => {

              this.$swal({
                title: 'Letra editada!',
                text: 'A letra desse hit foi alterada.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  // ATUALIZA A PÁGINA
                  this.$router.go()
                } else {
                  // ATUALIZA A PÁGINA
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Ops! Aconteceu um erro.',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // APROVAR HIT

  },
}
</script>

<style>
.text-with-line-breaks {
  white-space: pre-line;
}
</style>
