<template>
  <b-card
    no-body
  >

    <b-card-body>

      <h5>Avaliação do Hit</h5>
      <b-form-rating
        v-model="nota"
        no-border
        variant="warning"
        class="mb-1"
      />

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        block
        @click="aprovarHit"
      >
        Aprovar
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-danger"
        block
        @click="reprovarHit"
      >
        Reprovar
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BFormRating,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BFormRating,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nota: null,
    }
  },
  // watch: {
  //   nota(novoValor, valorAntigo) {
  //     alert(novoValor)
  //   }

  // },
  methods: {

    // APROVAR HIT
    aprovarHit() {
      this.$swal({
        title: 'Deseja aprovar esse hit?',
        text: 'Confirme a aprovação somente se o hit estiver dentro de todas as regras.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, aprovar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // API
          useJwt.hitAprovar({
            id: this.hit.id,
            statusAprovacaoHit: 1,
            nota: this.nota,
          })
            .then(response => {

              this.$swal({
                title: 'Hit Aprovado!',
                text: 'Esse hit está pronto para ser negociado.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.push({ name: 'aprovacoes-hit' })
                } else {
                  // REDIRECIONA PARA APROVAÇÕES
                  this.$router.push({ name: 'aprovacoes-hit' })
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Ops! Aconteceu um erro.',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
          // API
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // APROVAR HIT

    // REPROVAR HIT
    reprovarHit() {
      this.$swal({
      title: 'Deseja reprovar esse hit?',
      text: 'Informe o motivo da reprovação.',
      icon: 'warning',
      html:
        '<div class="swal2-row">' +
        '<input id="swal-input1" class="swal2-input" placeholder="Digite o motivo">' +
        '<select id="swal-select1" class="swal2-input">' +
        '<option value="" disabled selected>Selecione um motivo</option>' +
        '<option value="3">Informações Reprovadas</option>' +
        '<option value="4">Guia Reprovada</option>' +
        '<option value="2">Reprovado Geral</option>' +
        '</select>' +
        '</div>',
      showCancelButton: true,
      confirmButtonText: 'Sim, reprovar!',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      preConfirm: () => {
        const inputText = document.getElementById('swal-input1').value;
        const selectValue = document.getElementById('swal-select1').value;
        return {
          inputText,
          selectValue,
        };
      },
    }).then(result => {
        if (result.isConfirmed) {

          if (result.value.selectValue || result.value.inputText) {
            // API
            useJwt.hitReprovar({
              id: this.hit.id,
              statusAprovacaoHit: result.value.selectValue,
              motivoStatus: result.value.inputText, // PUXAR VALOR DO INPUT DO SWAL
            })
              .then(response => {

                this.$swal({
                  title: 'Hit Reprovado!',
                  text: 'O compositor receberá uma notificação sobre a reprovação.',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    // REDIRECIONA PARA APROVAÇÕES
                    this.$router.push({ name: 'aprovacoes-hit' })
                  } else {
                    // REDIRECIONA PARA APROVAÇÕES
                    this.$router.push({ name: 'aprovacoes-hit' })
                  }
                })
              })
              .catch(error => {
                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            // API
          } else {
            this.$swal({
              title: 'Ops! Aconteceu um erro.',
              text: 'Preencha o motivo da reprovação.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        } else {

          // CANCELAR SWEET

        }
      }).catch(error => {

      })
    },
    // REPROVAR HIT

  },
}
</script>

<style>

.swal2-input {
  margin-top: 15px;
}

</style>
