<template>
  <b-card class="mb-2">

    <b-row
      class="mt-1"
    >

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">

          <div class="d-flex flex-column">
            <div class="mb-1">
              <h5 class="mb-0">
                {{ hit.nomeComposicao }}
              </h5>
              <span class="card-text">Compositor: {{ hit.usuarioCompositorId }}</span>
            </div>
          </div>
        </div>

      </b-col>

      <b-col>

        <!-- User Stats -->
        <div class="d-flex mt-0">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                R$ {{ hit.valorLiberacao }}
              </h5>
              <small>Liberação</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                R$ {{ hit.valorExclusividade }}
              </h5>
              <small>Exclusividade</small>
            </div>
          </div>
        </div>

      </b-col>
    </b-row>
    <b-row>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="12"
      >

        <!-- User Stats -->
        <div class="m-1">

          <audio-player
            ref="audioPlayer"
            :audio-list="[hit.arquivo]"
            :show-playback-rate="false"
            :show-volume-button="false"
            :show-next-button="false"
            :show-prev-button="false"
            theme-color="#000"
          />


        </div>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import useUsersList from '../users-list/useUsersList'

import AudioPlayer from '@liripeng/vue-audio-player'
import VueAudio from 'vue-audio'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, AudioPlayer, VueAudio,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style lang="scss">

.audio-player .audio__progress-wrap {
  background: #CCC;
}

.audio-player.row {
  width: 100%;
}

</style>
